import * as React from "react";
import Layout from "../components/Layout/Layout";
import Button from "../components/Button/Button";
import shortCrookedLine from "../assets/img/short-crooked-line.svg";
import "../pages/error-page/error-page.scss";

const NotFoundPage = () => {
  return (
    <Layout>
      <div className="error-page-container">
        <h1 className="error-title-pagenotfound">Something went wrong</h1>
        <img src={shortCrookedLine} className="error-line" />
        <p className="error-msg-pagenotfound word">
          We're having trouble loading the page right now.
        </p>
        <div className="error-button-pagenotfound">
          <Button dark wide route="/" label="Go to careers Homepage" />
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const Head = () => <meta name="robots" content="noindex" />;
